import React from "react"
import { StyledFooter } from './styles'

const Footer = () => {
  return (
    <StyledFooter>
      Copyright &copy; The Bean Emporium
    </StyledFooter>
  )
}

export default Footer