import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'

export const Heading1 = styled.h1`
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin: 0 0 1rem;
`

export const Heading2 = styled.h2`
  font-family: 'Cormorant Garamond', serif;
  font-size: 4rem;
  margin: 0 0 1rem;
`
export const Heading3 = styled.h3`
  font-family: 'Cormorant Garamond', serif;
  margin: 0 0 1rem;
`
export const Heading4 = styled.h4`
  font-family: 'Cormorant Garamond', serif;
  margin: 0 0 1rem;
`
export const Heading5 = styled.h5`
  font-family: 'Cormorant Garamond', serif;
  margin: 0 0 1rem;
`
export const Heading6 = styled.h6`
  font-family: 'Cormorant Garamond', serif;
  margin: 0 0 1rem;
`

export const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    text-align: left;
  }
`

export const SectionHeading = styled(Heading2)`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    text-align: center;
  }
`

export const SectionSubheading = styled(Paragraph)`
  font-family: 'Cormorant Garamond', serif;
  font-size: 3rem;
  margin: 1rem 0 4rem;
  text-align: center;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    text-align: center;
  }
`
