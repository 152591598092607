import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
`

export const SectionContainer = styled.section`
  background-color: ${({altBackground}) => altBackground ? '#f5f5f5' : 'none'};
  margin: 0 auto;
  max-width: 1280px;
  padding: 5rem 2rem;
  width: 100%;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    padding: 10rem 2rem;
  }
`
