import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    flex-wrap: nowrap;
  }
`

export const NavItem = styled.a`
  color: #000000;
  display: inline-block;
  font-family: 'Cormorant Garamond', serif;
  font-size: 2rem;
  font-weight: 500;
  margin: 1rem;
  padding: 0 1rem;
  text-decoration: none;
`

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  width: 100%;
`
