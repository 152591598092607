import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    visibility: visible;
    opacity: 1;
  }
  body {
    background-color: #fefefe;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
  a {
    color: #fe6669;
    font-weight: 400;
    &:hover {
      color: #ff585b;
    }
  }
`

export default GlobalStyle
