import React from "react"
import PropTypes from "prop-types"

import Header from 'components/header'
import GlobalStyle from 'styles/global-style'
import Footer from 'components/footer'

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Header />
    {children}
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
